export const model_table = {
    3: {
        name: "💬 闲聊",
        description: "基础的闲聊模型",
        price: 0,
    },
    6: {
        name: "🖊️ 故事",
        description: "回复更长的模型",
        price: 1,
    },
    7: {
        name: "💬 闲聊+",
        description: "更好的闲聊模型",
        price: 7,
    },
    5: {
        name: "🖊️ 故事+" ,
        description: "更好的故事模型",
        price: 15,
    },
    2: {
        name: "🔬 Pro",
        description: "最聪明的模型",
        price: 10,
    },
    8: {
        name: "🖊️ 故事++" ,
        description: "新故事+模型",
        price: 15,
    },
    10: {
        name: "测试",
        description: "测试中的故事模型平替",
        price: 0,
    },
}

export function model_price_string(price: number) {
    if (price > 0) {
        return `${price}金币 / 消息`
    }
    return "免费"
}

export const model_list = [3, 6, 5, 8]
export const stable_model_list = [3, 6, 5]