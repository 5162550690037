import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function NavbarBackBtn() {
    const navigate = useNavigate()
    const location = useLocation()

    const goBackOrHome = () => {
        if (location.key === 'default') {
            navigate("/", { replace: true })
        } else {
            navigate(-1)
        }
    };

    return (
        <div className="flex-none">
            <button className="btn btn-ghost" onClick={goBackOrHome}>
                <i className="fa-solid fa-chevron-left"></i>
            </button>
        </div>
    )
} 


export default function NavbarContainer({ title = "", navEnd = () => <></>, hasColor = true, hasWrap = true, hasBg = true, children }) {

    

    const content = () => (
        <>
            <div className={`navbar fixed top-0 w-full z-[999] ${hasBg?"bg-base-100":""}`}>
                <NavbarBackBtn/>
                <div className="navbar-start">
                    {title && <a className="btn btn-ghost normal-case text-xl">{title}</a>}
                </div>
                <div className="navbar-end">
                    {navEnd()}
                </div>
            </div>
            <div className="h-full overflow-y-auto overflow-x-hidden bg-gradient-to-t from-grad-100 via-grad-200 to-grad-300 ">
                {hasWrap&&<div className="pb-32 pt-[64px] ">
                    {children}
                </div>}
                {!hasWrap&&children}
            </div>
        </>
    )

    return (
        <>
            {
                hasColor ? <div className={"flex flex-col bg-gradient-to-t h-screen from-grad-100 via-grad-200 to-grad-300"}>
                    {content()}
                </div> : <div className={"flex flex-col h-screen"}>
                    {content()}
                </div>
            }
        </>
    )
}