import toast from "react-hot-toast";
import Cookies from "js-cookie";
import InfinitePullToRefreshScroll from "../common/InfiniteScroller.tsx";
import React from "react";
import Markdown from "../common/markdown.tsx";
import { dateToText } from "../utils/date.js";
import { Link } from "react-router-dom";
import { parseImageUrlsFromMarkdown, removeImageUrlsFromMarkdown } from "../utils/markdown.ts";
import UserAvatar from "../common/UserAvatar.tsx";
import ImageWithSkeleton from "../common/ImageWithSkeleton.tsx";
import Zoom from "react-medium-image-zoom"

export function ContentItemInfo({item}) {
    return (
        <>
            <div className="flex flex-row justify-between w-full items-center">
                <Link className="flex flex-row gap-2 items-center" to={`/profile/${item.user_id}`}>
                    <UserAvatar user={{username: item.username, avatar: item.user_avatar}} size={8} /> {item.nickname}
                </Link>
                <div className="text-sm text-slate-400">{dateToText(new Date(item.time_created + "Z").getTime())}</div>
            </div>
            <div className="flex flex-col p-2 gap-1">
                <div className="line-clamp-1 text-white">
                    {item.title}
                </div>
                <div className="line-clamp-4 text-sm">
                    {item.description}
                </div>
            </div>
        </>
    )
}

function removeCodeBlocks(mdString) {
    mdString = mdString.replace(/```[\s\S]*?```/g, '');
    mdString = mdString.replace(/`[\s\S]*?`/g, '');
    return mdString;
}

export function ContentItemBody({ item, isTextImage }) {

    if (isTextImage) {
        const images = JSON.parse(item.content)
        return (
            images.length > 0 && 
                <div className="grid grid-cols-3 gap-1">
                    {images.map((image, index) => (
                        <Zoom>
                            <img key={index} src={image} className="w-full h-32 lg:h-48 object-cover" />
                        </Zoom>
                    ))}
                </div>
        )
    }

    const imageURL = () => {
        if (item.cover) {
            const images = parseImageUrlsFromMarkdown(item.cover)
            if (images.length) {
                return images[0]
            }
        }
        return item.portrait_url
    }

    const preview = (item) => {
        var md = ""
        if (item.cover) {
            md = removeImageUrlsFromMarkdown(item.cover)
        } else {
            const dialogs = JSON.parse(item.content)
            md = removeCodeBlocks(removeImageUrlsFromMarkdown(dialogs[0].dialog));
        }
        return (
            <div className="rounded-lg bg-primary w-full">
                <div className="m-3 line-clamp-3 text-sm prose">
                    <Markdown>{md}</Markdown>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-row gap-2">
            <ImageWithSkeleton className="h-24 w-20 contents rounded-lg" src={imageURL()} alt={`Portrait of ${item.name}`} />
            {preview(item)}
        </div>
    )
}

function ContentItemWithAvatar({ item, detailReturnFunc, copyReturnFunc }) {
    return (
        <div className="flex flex-col my-1 w-full bg-base-100 ">
            <div className="p-2 w-full flex flex-col" onClick={() => detailReturnFunc?.(item)}>
                <ContentItemInfo item={item} />
                <ContentItemBody item={item} isTextImage={true} />
            </div>
            <div className="flex flex-row items-center justify-between text-slate-400 text-sm p-1">
                <button className={`btn btn-ghost btn-sm w-1/3`} onClick={() => detailReturnFunc?.(item)}>
                    <i className="fa-regular fa-comment-dots"></i> {item.comment_count ? item.comment_count : "评论"}
                </button>
                <button className={`btn btn-ghost btn-sm w-1/3  ${item.liked ? "text-red-400" : ""}`} onClick={() => detailReturnFunc?.(item)}>
                    <i className="fa-regular fa-thumbs-up"></i> {item.likes ? item.likes : "点赞"}
                </button>
                <button className={`btn btn-ghost btn-sm w-1/3`} onClick={() => copyReturnFunc?.(item)}>
                    <i className="fa-regular fa-copy"></i> 复制
                </button>
            </div>
        </div>
    )
}

export function TemplateContentBrowse({
    user_id,
    group,
    sort = "combined",
    prefix = "content-browse",
    scrollHeight = "",
    detailReturnFunc,
    copyReturnFunc
}) {

    const fetchItems = async (page: number) => {
        let body: {
            page_size: number;
            page: number;
            prefer_not_viewed: boolean;
            group: any;
            sorting?: string;
            user_id?: string;
        } = {
            "page_size": 10,
            "page": page,
            "prefer_not_viewed": false,
            "group": group,
        };

        if (sort === "combined") {
            body = {
                ...body,
                "sorting": "combined",
            }
        } else if (sort === "mine") {
            body = {
                ...body,
                "sorting": "latest",
                "user_id": user_id,
            }
        }

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
                body: JSON.stringify(body)
            });
            const data = await res.json();
            return data["contents"]
        } catch (error) {
            toast.error("获取失败🙅");
            return []
        }
    }

    return (
        <InfinitePullToRefreshScroll prefix={prefix} scrollHeight={scrollHeight} fetchItems={fetchItems}>
            {({ items }) => (
                items.map((item, index) => (
                    <ContentItemWithAvatar item={item} detailReturnFunc={detailReturnFunc} copyReturnFunc={copyReturnFunc} key={index} />
                ))
            )}
        </InfinitePullToRefreshScroll>
    )
}

export default TemplateContentBrowse;