import toast from "react-hot-toast";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, } from "react-router-dom";
import NavbarContainer from "../NavbarContainer.tsx";
import { Character } from "../utils/type.ts";
import { fetchCharacterAPI } from "../utils/api.ts";
import LoadingPage from "../LoadingPage.tsx";
import { useUser } from "../context/UserContext.tsx";
import { removeImageUrlsFromMarkdown } from "../utils/markdown.ts";
import "./ContentPage.css"
import ChatBubble from "../common/ChatBubble.tsx";
import ContentComments from "./ContentComments.tsx";
import UserAvatar from "../common/UserAvatar.tsx";
import { dateToText } from "../utils/date.js";

interface Dialog {
    name: string;
    dialog: string;
}

interface ContentSectionProps {
    content: any;
    setContent: any;
    character: {
        name: string
        portrait_url: string;
        bio: string
    };
    liked: boolean
    children?: React.ReactNode;
}

const colors = ['text-white', 'text-blue-300', 'text-red-300', 'text-green-300', 'text-yellow-300', 'text-orange-300'];


const getDistinctNames = (dialogs) => {
    const seenNames = new Set();
    return dialogs.reduce((distinctNames, dialog) => {
        if (!seenNames.has(dialog.name)) {
            seenNames.add(dialog.name);
            distinctNames.push(dialog.name);
        }
        return distinctNames;
    }, []);
};

const ContentHeader = ({ content }) => {
    const images = JSON.parse(content.content);

    return (
        <div className="relative flex flex-col gap-4 p-2">
                <div className="flex flex-row justify-between w-full items-center">
                    <Link className="flex flex-row gap-2 items-center" to={`/profile/${content.user_id}`}>
                        <UserAvatar user={{username: content.user_name, avatar: content.user_avatar}} size={8} /> {content.nickname}
                    </Link>
                    <div className="text-sm text-slate-400">{dateToText(new Date(content.time_created + "Z").getTime())}</div>
                </div>
                <div className="text-bold text-white text-lg self-start">
                    {content.title}
                </div>
                <div className="relative flex flex-col">
                    <div className="self-start whitespace-pre-wrap">
                        {content.description}
                    </div>
                </div>
                
            </div>
    );
};


const UISection = ({ character, content, setContent, userLiked }) => {

    const [localLiked, setLocalLiked] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {user} = useUser()

    const like = async () => {
        if (loading) return
        if (userLiked || localLiked) {
            toast("已经赞过了")
            return
        }
        setLoading(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content.id}/like`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (res.status === 401) {
                navigate("/login")
                return
            }
            const data = await res.json()
            if (data["likes"]) {
                setContent(prevContent => ({
                    ...prevContent,
                    likes: prevContent["likes"] + 1
                }));
                setLocalLiked(true)
                toast.success("点赞成功")
            }
            setLoading(false)
        } catch (error) {
            toast.error("点赞失败")
            setLoading(false)
        }
    }

    const gotoCharacter = (character) => {
        if (character?.public) {
            navigate(`/character/${character?.id}`)
        }
    }

    const characterSection = () => (
        <button onClick={gotoCharacter} className="btn btn-ghost self-start">
            <img className="w-10 h-10 rounded-full" src={character?.avatar_url} />
            <p>{`${character?.name} >`}</p>
        </button>
    )

    const editSection = () => {
        if (content["user_name"] !== user?.username) return null
        return  (
            <button className="btn" onClick={() => navigate(`/content/create`, {
                state: {
                    "value": content["content"],
                    "character_id": character.id,
                    "content_id": content.id,
                    "title_value": content["title"],
                    "description_value": content["description"],
                    "cover_value": content["cover"],
                    "bgm_value": content["bgm"],
                }
            })}>
                <i className="fa-solid fa-edit"></i>
            </button>
        )
    }


    return (
        <div className="flex flex-col">
            {character && character.public && characterSection()}
            <button className={`flex flex-row gap-2 items-center self-center btn ${(userLiked || localLiked) ? "text-red-400" : "text-white"}`} disabled={loading} onClick={like}>
                <i className="fa-solid fa-thumbs-up"></i> <p>{content["likes"] ? content["likes"] : "点赞"}</p>
            </button>
            <div className="divider"></div>
            <ContentComments content={content} />
        </div>
    )
}

function getContentFromDialog(dialog) {
    console.log(dialog)
    return removeImageUrlsFromMarkdown(dialog.dialog)
}

const ContentSection: React.FC<ContentSectionProps> = ({ content, setContent, character, liked }) => {
    const dialogs: Dialog[] = JSON.parse(content["content"]);
    const names = getDistinctNames(dialogs);

    const getColorForName = (name: string) => {
        return colors[names.indexOf(name) % colors.length];
    };

    return (
        <div className="flex flex-col h-full justify-center items-center">
            <div className="w-full h-full flex flex-col space-y-2">
                    <div className="pt-20 p-4">
                    <ContentHeader content={content} />
                    <div className="divider"></div>
                    {
                        dialogs.map((dialog, index) => (
                            <div>
                                <div className={`${getColorForName(dialog.name)}`}>{dialog.name}</div>
                                <ChatBubble key={index} role={"assistant"} content={getContentFromDialog(dialog)} />
                            </div>
                        ))
                    }
                    </div>
                    <UISection character={character} content={content} setContent={setContent} userLiked={liked} />
                </div>
        </div>
    );
};


export function ContentCard({ content_id }) {
    const [character, setCharacter] = useState<Character | null>()
    const [content, setContent] = useState<any>(null)
    const [liked, setLiked] = useState<any>(false)
    const [loading, setLoading] = useState<any>(false)

    const fetchLiked = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}/like`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json()
            setLiked(!!data["liked"])
        } catch (error) {
            console.log(error)
        }
    }

    const fetchContent = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}`, {
                method: 'GET',
            });
            const data = await res.json();
            console.log(data)
            setContent(data)

            // fetch character
            if (data["character_id"]) {
                const character = await fetchCharacterAPI(data["character_id"])
                setCharacter(character)
            }
        } catch (error) {
            toast.error("获取失败")
            return
        }
    }

    const init = () => {
        if (!loading) {
            setLoading(true)
            fetchLiked().then(()=>fetchContent()).then(()=>setLoading(false))
        }
    }

    useEffect(() => {
        init()
    }, []);

    if (!content) {
        return <LoadingPage />
    }

    return (
        <div className="h-full w-full flex justify-center items-center">
            <div className="h-full w-full">
                {character && <ContentSection content={content} setContent={setContent} character={character} liked={liked}/>}
            </div>
        </div>
    )

}

export function ContentPage() {

    const { content_id } = useParams()
    const { user } = useUser()
    const navigate = useNavigate()

    const navEnd = () => (
        <>
            {user && user.group === "TopicAuthor" &&
                <button className="btn rounded-full btn-error" onClick={() => navigate(`mod`)}>
                    MOD
                </button>
            }
        </>
    )

    return (
        <NavbarContainer title="" navEnd={navEnd} hasColor={false} hasWrap={false} hasBg={true}>
            <div className="flex flex-col justify-center items-center h-screen w-full">
                <ContentCard content_id={content_id} />
            </div>
        </NavbarContainer>
    )
}

export default ContentPage;