import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast"
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { dateToText } from '../utils/date.js';
import LoadingPage from '../LoadingPage.tsx';
import 'react-medium-image-zoom/dist/styles.css'
import TextareaAutosize from 'react-textarea-autosize';
import ChatBubble from '../common/ChatBubble.tsx';
import {model_price_string, model_table} from '../chat/modelList.ts';
import { useUser } from '../context/UserContext.tsx';
import useGroupBot from "./useGroupBot.ts";
import { parseImageUrlsFromMarkdown } from "../utils/markdown.ts";
import NewTopicButton from "../chat/NewTopicButton.tsx";


function GroupChatPage() {

    const { groupID } = useParams()

    const modelModalRef = useRef<HTMLDialogElement | null>(null);
    const otherOptsDialogRef = useRef<HTMLDialogElement | null>(null);
    const [confirmRestart, setConfirmRestart] = useState(false);
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const {user} = useUser()

    const {
        processing,
        group,
        history,
        userText,
        send,
        deleteEvent,
        regenEvent,
        editEvent,
        clearHistory,
        cookAIText,
    } = useGroupBot(groupID || "0")

    const navigate = useNavigate()

    const historyEndRef = useRef<null | HTMLDivElement>(null)

    const [shareMode, setShareMode] = useState(false);

    const [model, setModel] = useState(6);

    useEffect(() => {
        if (!group) return
        const savedModel = sessionStorage.getItem('model-' + groupID); // saved model is per character
        if (savedModel) {
            setModel(JSON.parse(savedModel))
            return
        }
    }, [group])
    
    useEffect(() => {
        if (!group) {
            return
        }
        sessionStorage.setItem('model-' + groupID, JSON.stringify(model));
    }, [model]);

    const scrollToBottom = () => {
        historyEndRef.current?.scrollIntoView()
    }

    useEffect(() => {
        if (history?.length) {
            scrollToBottom()
        }
    }, [history]);
    
    const handleModelChange = (model) => {
        setModel(model)
    };

    if (group === null) {
        return <LoadingPage />
    }

    const back = () => {
        navigate(-1)
    }

    function showOtherOptsDialog() {
        if (otherOptsDialogRef && otherOptsDialogRef.current) otherOptsDialogRef.current.showModal()
    }

    function handleRestartCharacter(){
        if (!confirmRestart) {
            setConfirmRestart(true)
            return
        }
        if (group) {
            clearHistory(groupID)
            if (otherOptsDialogRef && otherOptsDialogRef.current) otherOptsDialogRef.current.close()
        }
    }

    const startShare = () => {
        setShareMode(true)
        setCheckedState(new Array(history?.length).fill(false))
        otherOptsDialogRef.current?.close()
    }

    const otherOptsDialog = () => {
        return (
            <dialog className="modal" ref={otherOptsDialogRef}>
                <div className="modal-box flex flex-col space-y-8">
                    <Link className="btn btn-outline btn-secondary" to={`/groupchat/create/${groupID}`} >
                        <i className="fa-solid fa-eye" />
                        查看群聊
                    </Link>
                    <button className="btn btn-outline btn-secondary" onClick={startShare} >
                        <i className="fa-solid fa-share-from-square" />
                        分享对话
                    </button>
                    <button onClick={handleRestartCharacter} className="btn btn-error">
                        <i className="fa-solid fa-power-off" />
                        {confirmRestart ? "再次确认重启群聊": "重启群聊"}
                    </button>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }

    const getAvatar = (character_id: string) => {
        const members = group.members.filter(member => character_id == member.id)
        if (members.length === 0) return ""
        return members[0].avatar_url
    }

    const getPortrait = (character_id: string) => {
        const members = group.members.filter(member => character_id == member.id)
        if (members.length === 0) return ""
        return members[0].portrait_url
    }

    const getName = (character_id: string) => {
        const members = group.members.filter(member => character_id == member.id)
        if (members.length === 0) return ""
        return members[0].name
    }

    const goToShare = ()=>{
        if (!history) return
        const selectedHistory = history.filter((item, index) => checkedState[index]);
        const value = JSON.stringify(selectedHistory.map((item) => {
            var content = item["content"]
            return {
                name: item["role"] === "user" ? user?.nickname : getName(item["character_id"]),
                dialog: content,
            }
        }))

        navigate(`/content/create`, {
                state: {
                    "value": value,
                    "character_id": group.members[0].id,
                    "content_id": null,
                    "character_options": group.members,
                }
            }
        )
    }

    const shareNavbar = () => (
        <div className="navbar fixed top-0 w-full z-[999] bg-base-100">
            <div className="navbar-start">
                <button className="btn btn-ghost text-error" onClick={()=>setShareMode(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="navbar-center gap-1 w-1/2 justify-center">
                <div className="text-xl line-clamp-1">选择对话分享</div>
            </div>
            <div className="navbar-end">
                <button className="btn btn-ghost text-success" onClick={goToShare} ><i className="fa-solid fa-check"></i></button>
            </div>
        </div>
        )

    const navbar = () => {
        return (
        <div className="navbar fixed top-0 w-full z-[999] bg-base-100">
            <div className="navbar-start">
                <button className="btn btn-ghost" onClick={back}>
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
            </div>
            <div className="navbar-center gap-1 w-1/2 justify-center">
                <div className="text-xl line-clamp-1">{group.name}</div>
                <div className={`btn btn-xs`} onClick={()=>handleOpen(modelModalRef)}>{model_table[model].name}</div>
            </div>
            <div className="navbar-end">
                <button className="btn btn-ghost" onClick={showOtherOptsDialog} ><i className="fa-solid fa-ellipsis"></i></button>
            </div>
        </div>
        )
    }
    
    const handleOpen = (modalRef) => {
        if (modalRef.current) {
            modalRef.current.showModal()
        }
    }

    const modelButton = (forModel: number) => (
        <div
            onClick={() => handleModelChange(forModel)}
            className={`btn btn-secondary flex justify-between items-center ${model != forModel && "btn-outline"}`}
        >
            <p className="">{model_table[forModel].name}: {model_table[forModel].description}</p>
            <p className="text-xs text-slate-400">{model_price_string(model_table[forModel].price)}</p>
        </div>
    )

    const selectModelDialog = () => {
        return (
            <dialog className="modal" ref={modelModalRef}>
                <div className="modal-box flex flex-col space-y-4">
                    <h3 className="font-bold text-lg">选择一个AI模型</h3>
                    {modelButton(6)}
                    {modelButton(5)}
                    {modelButton(2)}
                    <Link to="/me/coin" className="underline text-secondary">查看余额</Link>
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn">完成</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }
    
    const goToCoin = ()=>(
        <div className="flex justify-center"><div className="text-center bg-base-100 p-4 rounded-lg">尝试 <Link to="" onClick={()=>handleOpen(modelModalRef)} className="text-secondary underline">切换模型</Link>，或者前往 <Link to="/me/coin" className="text-secondary underline">我的钱包</Link></div></div>
    )

    const handleCheckboxChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    };

    const requestReply = (character_id: string) => {
        send(character_id, model)
    }

    return (
        <div className={`flex flex-col relative h-screen`}>
            {!shareMode && navbar()}
            {shareMode && shareNavbar()}
            <div className="fixed z-[-1] w-full h-full flex justify-center items-center bg-gradient-to-t from-grad-100 via-grad-200 to-grad-300" >
            </div>
            <div 
                className={`chat-container flex flex-col h-full w-screen`}
            >
                <div className={`w-screen md:w-1/2 self-center overflow-y-auto overflow-x-hidden`}>
                    <div className="pt-[64px] pb-40">
                        {history && history.map((entry, index, arr) => (
                            <div className={shareMode ? "flex flex-row items-center": ""}>
                                {shareMode && <input
                                    type="checkbox"
                                    className="checkbox m-4 checkbox-lg checkbox-success rounded-full"
                                    checked={checkedState[index]}
                                    onChange={() => handleCheckboxChange(index)}
                                />}
                                <ChatEntry key={index}
                                    entry={entry} prevEntry={arr[index - 1]}
                                    character_id={entry["character_id"]}
                                    avatar={getAvatar(entry["character_id"])}
                                    deleteEvent={async ()=>await deleteEvent(entry["id"])}
                                    regenEvent={async ()=>await regenEvent(entry["character_id"], entry["id"], model)} 
                                    editEvent={async (c: string)=>await editEvent(entry["id"], c)}
                                    isLast={index == history.length-1}
                                    shareMode={shareMode}
                                    startShare={startShare}
                                />
                            </div>
                        ))}
                        { processing && <div className="px-12"><LoadingBubble /></div> }
                        <div ref={historyEndRef} />
                    </div>
                </div>
            </div>
            {!shareMode && <div className={`fixed bottom-0 w-full z-[999] flex-col flex w-full md:w-1/2 self-center justify-center items-center`}>
                <div className="flex flex-row gap-2 p-2 justify-start w-full overflow-x-auto">
                    {group.members.map((member, index)=>(
                        <button className="btn btn-circle btn-ghost" disabled={processing} key={index} onClick={()=>requestReply(member.id)}>
                            <img src={member.avatar_url} className="w-12 h-12 rounded-full" />
                        </button>
                    ))}
                </div>
                <ChatInput send={(input) => userText(input, model)} processing={processing} placeholder="发送消息" cookAIText={cookAIText} characters={group.members} />
            </div>}
            {selectModelDialog()}
            {otherOptsDialog()}
        </div>
    );
}

const SelectCharacterButton = ({ characters, processing, cookAIText }) => {
    const ref = useRef<HTMLDialogElement>(null)

    return (
        <>
            <button className="btn btn-circle" onClick={() => ref.current?.showModal()}>
                <i className="fa-regular fa-lightbulb"></i>
            </button>
            <dialog className="modal" style={{marginLeft: 0}} ref={ref}>
                <div className="modal-box max-h-[66vh]">
                    <h3 className="font-bold text-lg">选择一个角色</h3>
                    <div className="my-4 flex flex-col gap-2">
                        {characters.map((member) => (
                                <div  key={member.id} className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center gap-2">
                                        <img src={member.avatar_url} alt="avatar" className="w-12 h-12 rounded-full" />
                                        {member.name}
                                    </div>
                                    <div onClick={()=>ref.current?.close()}>
                                        <NewTopicButton character={member} cookAIText={cookAIText} processing={processing}/>
                                    </div>
                                </div>
                        ))}
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </>
        
    );
};

export function ChatInput({send, processing, placeholder, cookAIText, characters}) {
    const [input, setInput] = useState("");

    const textAreaRef = useRef<null | HTMLTextAreaElement>(null)
    const enterSend  = (()=>{
        const savedEnterSend = localStorage.getItem('enterSend');
        return savedEnterSend !== null ? JSON.parse(savedEnterSend) : true;
    })()

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (processing || !enterSend) {
            return
        }
        if (e.keyCode === 13 && !e.shiftKey && input.trim() !== '') {
            e.preventDefault()
            setInput("")
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (isMobile) {
                textAreaRef.current?.blur();
            }
            send(input)
        }
    }

    const sendWrapper = () => {
        if (!processing && input) {
            send(input)
            setInput("")
        }
    }

    return (
        <div className="bg-base-100 p-4 space-x-4 w-full flex flex-row">
            <TextareaAutosize placeholder={placeholder} className="textarea textarea-bordered resize-none grow w-full"
                maxRows={5}
                value={input}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                ref={textAreaRef}
            />
            {input && <button className="btn rounded-full" onClick={sendWrapper} disabled={processing}>
                <i className="fa-regular fa-paper-plane"></i>
            </button>}
            {!input && <SelectCharacterButton characters={characters} processing={processing} cookAIText={cookAIText} />}
        </div>
    )
}

function LoadingBubble(props) {
    return (
        <div className={`chat chat-start px-4 py-2`}>
            <div className={`break-words max-w-full chat-bubble chat-bubble-primary`}>
                <span className="loading loading-dots loading-sm"></span>
            </div>
        </div>
    )
}

function DeleteEvent({ onDelete }) {
  
    async function onDeleteWrapper() {
      await onDelete()
      toast.success("删除成功")
    }
  
    return (
        <button className="btn btn-sm" onClick={onDeleteWrapper}>
            <i className="fa fa-trash-can"></i>
        </button>
    );
}

function RegenEvent({ onRegen }) {
  
    return (
        <button className="btn btn-sm" onClick={onRegen}>
            <i className="fa-solid fa-arrow-rotate-left"></i>
        </button>
    );
}


function EditEvent({ content, onEdit }) {

    const editDialogRef = useRef<HTMLDialogElement | null>(null)
    const [input, setInput] = useState<string>(content)
    const [loading, setLoading] = useState(false)

    const showModal = () => {
        if (editDialogRef.current) {
            editDialogRef.current.showModal()
        }
    }

    const onEditWrapper = async () => {
        if (loading) {
            return
        }
        setLoading(true)
        await onEdit(input)
        setLoading(false)
        editDialogRef.current?.close()
    }

    const editDialog = () => (
        <dialog className="modal" ref={editDialogRef}>
            <div className="modal-box flex flex-col gap-2">
                <h3 className="font-bold text-lg">编辑消息</h3>
                <TextareaAutosize placeholder="发送消息" className="textarea textarea-bordered resize-none grow"
                    maxRows={5}
                    value={input}
                    onChange={e => setInput(e.target.value)}
                />
                <div className="modal-action">
                    <form method="dialog" className="space-x-4">
                        <button className="btn" disabled={loading} onClick={onEditWrapper}>保存</button>
                    </form>
                </div>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    )
  
    return (
        <>
            {editDialog()}
            <button className="btn btn-sm" onClick={showModal}>
                <i className="fa-solid fa-edit"></i>
            </button>
        </>
    );
}

function ChatEntry(props) {
    const { entry, prevEntry, character_id, avatar, deleteEvent, regenEvent, editEvent, isLast, shareMode, startShare } = props
    const isAI = entry.role === "assistant"
    const isUser = entry.role === "user"
    const navigate = useNavigate()

    var hasLag = true
    if (prevEntry) {
        if (entry.date - prevEntry.date < 60 * 60 * 1000) {
            hasLag = false
        }
    }

    const dateBadge = () => {
        if (!hasLag || !entry.date) {
            return null
        }
        return (
            <div className="flex justify-center items-center p-5">
                <div className="badge badge-ghost">
                    {dateToText(entry.date)}
                </div>
            </div>
        )
    }
    
    if (entry["hide"]) {
        return null
    }

    return (
        <div>
            <div className="px-4 py-4">
            {!shareMode && dateBadge()}
            <div className={`flex flex-row items-end w-full ${isUser ? "justify-end": ""}`}>
                {avatar &&
                    <img src={avatar} onClick={()=>navigate(`/character/${character_id}`)} className="w-12 h-12 rounded-full p-1"/>
                }
                <ChatBubble role={entry["role"]} content={entry["content"]} />
            </div>
            {!shareMode && <div className={`flex flex-row space-x-4  ${isUser ? "justify-end px-4": "px-16"}`}>
                {isLast && entry.id && isAI && <RegenEvent onRegen={regenEvent} />}
                {isLast && entry.id && isAI && !entry["broadcast_id"] && <EditEvent onEdit={editEvent} content={entry["content"]}/>}
                {isLast && entry.id && <DeleteEvent onDelete={deleteEvent} />}
                {isLast && <button className="btn btn-sm" onClick={startShare}>
                    <i className="fa-solid fa-share-from-square"></i>
                </button>}
            </div>}
            </div>
        </div>
    )
}

export default GroupChatPage;
