import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import PullToRefresh from "react-simple-pull-to-refresh";

interface InfinitePullToRefreshScrollProps {
    prefix: string;
    fetchItems: (page: number) => Promise<any[]>;
    scrollHeight?: string | number;
    children: ({ items }: { items: any[] }) => React.ReactNode;
}

export default function InfinitePullToRefreshScroll({ prefix, fetchItems, scrollHeight = "", children }: InfinitePullToRefreshScrollProps) {
    const itemsID = prefix + '-items'
    const hasMoreID = prefix + '-hasmore'
    const indexID = prefix + '-index'
    const [items, setItems] = useState<any>(
        prefix && sessionStorage.getItem(itemsID) ? JSON.parse(sessionStorage.getItem(itemsID)!) : []
    );
    const [hasMore, setHasMore] = useState<boolean>(
        prefix && sessionStorage.getItem(hasMoreID) ? JSON.parse(sessionStorage.getItem(hasMoreID)!) : true
    );
    const [index, setIndex] = useState<number>(
        prefix && sessionStorage.getItem(indexID) ? JSON.parse(sessionStorage.getItem(indexID)!) : 1
    );

    useEffect(() => {
        if (prefix) {
            sessionStorage.setItem(itemsID, JSON.stringify(items));
            sessionStorage.setItem(hasMoreID, JSON.stringify(hasMore));
            sessionStorage.setItem(indexID, JSON.stringify(index));
        }
    }, [items, hasMore, index]);

    const refresh = async () => {
        setItems([])
        setIndex(1)
        setHasMore(true)
        fetchMore(1)
    }

    useEffect(() => {
        refresh();
        if (items.length === 0) {
            fetchMore(1)
        }
    }, [])

    const fetchMore = async (page: number) => {
        try {
            const items = await fetchItems(page)
            if (items.length === 0) {
                setHasMore(false)
            } else {
                setHasMore(true)
                if (page === 1) {
                    setItems(items)
                } else {
                    setItems((prevItems) => [...prevItems, ...items]);
                }
                setIndex(page + 1)
            }
            return
        } catch (e) {
            toast.error("获取失败🙅");
            return
        }
    }

    return (
        <PullToRefresh
            onRefresh={refresh}
            refreshingContent={
                <div className="w-full text-center p-4">
                    <b>下拉刷新</b>
                </div>
            }
        >
            <InfiniteScroll
                className="w-full flex flex-wrap"
                dataLength={items.length}
                next={() => fetchMore(index)}
                hasMore={hasMore}
                loader={<span className="loading loading-spinner loading-xs"></span>}
                endMessage={
                    <div className="w-full text-center p-4">
                        <b>没有更多了</b>
                    </div>
                }
                height={scrollHeight}
            >
                {children({ items })}
            </InfiniteScroll>
        </PullToRefresh>
    )
}