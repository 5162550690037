import toast from "react-hot-toast";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, } from "react-router-dom";
import NavbarContainer from "../NavbarContainer.tsx";
import LoadingPage from "../LoadingPage.tsx";
import { useUser } from "../context/UserContext.tsx";
import ContentComments from "./ContentComments.tsx";
import "./ContentPage.css"
import Zoom from "react-medium-image-zoom"
import UserAvatar from "../common/UserAvatar.tsx";
import { dateToText } from "../utils/date.js";


const UISection = ({ content, setContent, userLiked }) => {

    const [localLiked, setLocalLiked] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {user} = useUser()

    const like = async () => {
        if (loading) return
        if (userLiked || localLiked) {
            toast("已经赞过了")
            return
        }
        setLoading(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content.id}/like`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (res.status === 401) {
                navigate("/login")
                return
            }
            const data = await res.json()
            if (data["likes"]) {
                setContent(prevContent => ({
                    ...prevContent,
                    likes: prevContent["likes"] + 1
                }));
                setLocalLiked(true)
                toast.success("点赞成功")
            }
            setLoading(false)
        } catch (error) {
            toast.error("点赞失败")
            setLoading(false)
        }
    }


    return (
        <button className={`flex flex-row gap-2 items-center self-center btn ${(userLiked || localLiked) ? "text-red-400" : "text-white"}`} disabled={loading} onClick={like}>
            <i className="fa-solid fa-thumbs-up"></i> <p>{content["likes"] ? content["likes"] : "点赞"}</p>
        </button>
    )
}

const TextImageContentSection = ({ content, setContent, liked, isModal = false }) => {
    const images = JSON.parse(content.content);

    return (
        <div className="relative flex flex-col h-full gap-4">
            <div className="relative flex flex-col gap-4 p-2">
                <div className="flex flex-row justify-between w-full items-center">
                    <Link className="flex flex-row gap-2 items-center" to={`/profile/${content.user_id}`}>
                        <UserAvatar user={{username: content.user_name, avatar: content.user_avatar}} size={8} /> {content.nickname}
                    </Link>
                    <div className="text-sm text-slate-400">{dateToText(new Date(content.time_created + "Z").getTime())}</div>
                </div>
                <div className="text-bold text-white text-lg self-start">
                        {content.title}
                    </div>
                <div className="relative flex flex-col">
                    <div className="self-start whitespace-pre-wrap">
                        {content.description}
                    </div>
                    {images.length > 0 && (
                        <div className="mt-4 grid grid-cols-3 gap-1">
                            {images.map((image, index) => (
                                <Zoom>
                                    <img key={index} src={image} className="w-full h-auto" />
                                </Zoom>
                            ))}
                        </div>
                    )}
                </div>
                
            </div>
            <UISection content={content} setContent={setContent} userLiked={liked} />
            <div className="divider"></div>
            <ContentComments content={content} isModal={isModal} />
        </div>
    );
};

export function TextImageContentCard({ content_id }) {
    const [content, setContent] = useState<any>(null)
    const [liked, setLiked] = useState<any>(false)
    const [loading, setLoading] = useState<any>(false)

    const fetchLiked = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}/like`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json()
            setLiked(!!data["liked"])
        } catch (error) {
            console.log(error)
        }
    }

    const fetchContent = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}`, {
                method: 'GET',
            });
            const data = await res.json();
            console.log(data)
            setContent(data)
        } catch (error) {
            toast.error("获取失败")
            return
        }
    }

    const init = () => {
        if (!loading) {
            setLoading(true)
            fetchLiked().then(()=>fetchContent()).then(()=>setLoading(false))
        }
    }

    useEffect(() => {
        init()
    }, []);

    if (!content) {
        return <LoadingPage />
    }

    return (
        <div className="h-full w-full flex">
            <div className="h-full w-full md:w-1/2">
                {<TextImageContentSection content={content} setContent={setContent} liked={liked}/>}
            </div>
        </div>
    )

}

export function TemplateTextImageContentCard({ content_id }) {
    const [content, setContent] = useState<any>(null)
    const [liked, setLiked] = useState<any>(false)
    const [loading, setLoading] = useState<any>(false)

    const fetchLiked = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}/like`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json()
            setLiked(!!data["liked"])
        } catch (error) {
            console.log(error)
        }
    }

    const fetchContent = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}`, {
                method: 'GET',
            });
            const data = await res.json();
            console.log(data)
            setContent(data)
        } catch (error) {
            toast.error("获取失败")
            return
        }
    }

    const init = () => {
        if (!loading) {
            setLoading(true)
            fetchLiked().then(()=>fetchContent()).then(()=>setLoading(false))
        }
    }

    useEffect(() => {
        init()
    }, []);

    if (!content) {
        return <LoadingPage />
    }

    return (
        <TextImageContentSection content={content} setContent={setContent} liked={liked} isModal={true}/>
    )
}

export function TextImageContentPage() {

    const { content_id } = useParams()
    const { user } = useUser()
    const navigate = useNavigate()
    const audioRef = useRef<HTMLAudioElement>(null)

    const navEnd = () => (
        <>
            {user && user.group === "TopicAuthor" &&
                <button className="btn rounded-full btn-error" onClick={() => navigate(`mod`)}>
                    MOD
                </button>
            }
        </>
    )

    return (
        <NavbarContainer title="" navEnd={navEnd} hasColor={false} hasWrap={false} hasBg={true}>
            <div className="flex flex-col h-full w-full items-center mt-16">
                <TextImageContentCard content_id={content_id}/>
            </div>
        </NavbarContainer>
    )
}

export default TextImageContentPage;